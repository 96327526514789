import Axios from 'axios';
import { servicePath } from '../constants/defaultValues';

export default {
    async Login(formData) {
        try {
            const response = await Axios.post(`${servicePath}auth/login`, formData)
            return response.data;
        }
        catch (e) {
            return  e
        }

    }
}

